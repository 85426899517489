<template>
  <div class="preloader-mask" v-if="activeRequestsCount > 0">
    <div id="preloader">
      <div id="loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  computed: {
    activeRequestsCount() {
      return this.$store.getters.getActiveRequestsCount;
    },
  },
};
</script>

<style scoped lang="scss">
  .preloader-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 1050;
  }
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #loader {
    $loaderSize: 150px;
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: $loaderSize;
    height: $loaderSize;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: lighten($blueColor, 20%);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    @media screen and (max-width: 768px) {
      $loaderSize: 100px;
      width: $loaderSize;
      height: $loaderSize;
    }
  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: lighten($blueColor, 10%);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $blueColor;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @-webkit-keyframes spin {
    0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
